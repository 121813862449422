<template>
  <div>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <div class="mx-4 elevation-10 rounded-lg pa-4">
          <v-row class="ml-0">
            <v-col cols="12" sm="12" md="6">
              <div class="font-weight-black mb-2">TREND PENGUNJUNG BULANAN</div>
              <div>
                <v-btn-toggle v-model="toggleValue" dense>
                  <v-btn
                    value="last6months"
                    @click="toggleClick('last6months')"
                  >
                    <span class="caption font-weight-bold"
                      >6 Bulan Terakhir</span
                    >
                  </v-btn>

                  <v-btn
                    value="last12months"
                    @click="toggleClick('last12months')"
                  >
                    <span class="caption font-weight-bold"
                      >Satu Tahun Terakhir</span
                    >
                  </v-btn>
                </v-btn-toggle>
              </div>
              <div>
                <apexcharts
                  type="area"
                  height="300"
                  :options="chartOptionsBar1"
                  :series="seriesBar1"
                ></apexcharts>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="font-weight-black mb-2">
                TREND PENGUNJUNG MINGGUAN
              </div>
              <div>
                <v-btn-toggle v-model="toggleValue" dense>
                  <v-btn value="last6weeks" @click="toggleClick('last6months')">
                    <span class="caption font-weight-bold"
                      >6 Minggu Terakhir</span
                    >
                  </v-btn>

                  <v-btn
                    value="last12weeks"
                    @click="toggleClick('last12months')"
                  >
                    <span class="caption font-weight-bold"
                      >12 Minggu Terakhir</span
                    >
                  </v-btn>
                </v-btn-toggle>
              </div>
              <div>
                <apexcharts
                  type="area"
                  height="300"
                  :options="chartOptionsBar2"
                  :series="seriesBar2"
                ></apexcharts>
              </div>
            </v-col>
          </v-row>
          <v-btn color="success" small @click="reloadChart">
            <v-icon small>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text>
        <div class="mx-4 elevation-10 rounded-lg pa-4">
          <v-row class="ml-0">
            <v-col cols="12" sm="12" md="6">
              <div class="font-weight-bold">TOTAL PENGUNJUNG</div>
              <apexcharts
                type="bar"
                height="500"
                :options="chartOptionsBar3"
                :series="seriesBar3"
              ></apexcharts>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <div class="font-weight-bold">TOTAL PENGUNJUNG</div>
              <apexcharts
                width="500"
                height="250"
                :options="chartOptionsPie1"
                :series="seriesPie1"
              ></apexcharts>
            </v-col>
          </v-row>
          <v-btn color="success" small @click="reloadChart">
            <v-icon small>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import FVisitorService from "@/services/apiservices/f-visitor-service";

export default {
  name: "ChartVisitorDialogContent",
  components: { apexcharts: VueApexCharts },
  props: {},
  data() {
    return {
      toggleValue: "",

      series: [
        {
          name: "City",
          data: [44, 55, 41, 67, 22],
        },
      ],
      seriesBar1: [
        {
          name: "City",
          data: [44, 55, 41, 67, 22],
        },
      ],
      seriesBar2: [
        {
          name: "City",
          data: [44, 55, 41, 67, 22],
        },
      ],
      seriesBar3: [
        {
          name: "City",
          data: [44, 55, 41, 67, 22],
        },
      ],

      categoriesBar1: [],
      chartOptionsBar1: {
        chart: {
          height: 300,
          type: "bar",
        },
      },
      categoriesBar2: [],
      chartOptionsBar2: {
        chart: {
          height: 300,
          type: "bar",
        },
      },
      categoriesBar3: [],
      chartOptionsBar3: {
        chart: {
          height: 300,
          type: "bar",
          horizontal: true,
        },
      },

      chartOptionsPie1: {
        chart: {
          height: 500,
          type: "pie",
        },
      },
      labelsPie1: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
      ],
      seriesPie1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15],
    };
  },
  watch: {
    seriesBar1: {
      handler: function (value) {
        if (value) this.reloadChartOptionBar1();
      },
    },
  },
  computed: {},
  methods: {
    reloadChart() {
      this.reloadChartArea1();
      this.reloadChartArea2();
      this.reloadChartArea3();
      this.reloadChartPie1();
    },

    reloadChartArea1() {
      let dateNow = new Date();
      let lastMonthAgo = 5;
      let startDateLong = new Date(
        dateNow.getFullYear(),
        dateNow.getMonth() - lastMonthAgo,
        1
      ).getTime();
      let endDateLong = dateNow.getTime();

      let thisMonthFrom1 = dateNow.getMonth() + 1;
      FVisitorService.getVisitorCountByCityAndMonth(
        startDateLong,
        endDateLong
      ).then((response) => {
        console.log(JSON.stringify(response.data));

        this.categoriesBar1 = [];
        for (let i = thisMonthFrom1 - lastMonthAgo; i <= thisMonthFrom1; i++) {
          this.categoriesBar1.push(i);
        }

        let uniqueCity = [...new Set(response.data.map((item) => item.city))];
        this.seriesBar1 = [];
        uniqueCity.forEach((x) => {
          let theName = x;
          let theData = [];

          for (
            let i = thisMonthFrom1 - lastMonthAgo;
            i <= thisMonthFrom1;
            i++
          ) {
            const arr = response.data.filter(
              (f) => f.city === x && f.month === i
            );
            if (arr.length > 0) theData.push(arr[0].totalVisitorCount);
            else theData.push(0);
          }
          this.seriesBar1.push({
            name: theName,
            data: theData,
          });
        });
        console.log(this.seriesBar1);

        this.reloadChartOptionBar1();
      });
    },

    reloadChartArea2() {
      let dateNow = new Date();
      let lastWeekAgo = 5;
      let startDateLong = new Date(
        dateNow.getFullYear(),
        dateNow.getMonth() - lastWeekAgo,
        dateNow.getDate() - lastWeekAgo * 7
      ).getTime();
      let endDateLong = dateNow.getTime();

      let thisWeekNumber = this.getWeekNumber(dateNow);
      FVisitorService.getVisitorCountByCityAndWeek(
        startDateLong,
        endDateLong
      ).then((response) => {
        // console.log(JSON.stringify(response.data))

        this.categoriesBar2 = [];
        for (let i = thisWeekNumber - lastWeekAgo; i <= thisWeekNumber; i++) {
          this.categoriesBar2.push(i);
        }

        let uniqueCity = [...new Set(response.data.map((item) => item.city))];
        this.seriesBar2 = [];
        uniqueCity.forEach((x) => {
          let theName = x;
          let theData = [];

          for (let i = thisWeekNumber - lastWeekAgo; i <= thisWeekNumber; i++) {
            const arr = response.data.filter(
              (f) => f.city === x && f.week === i
            );
            if (arr.length > 0) theData.push(arr[0].totalVisitorCount);
            else theData.push(0);
          }
          this.seriesBar2.push({
            name: theName,
            data: theData,
          });
        });

        this.reloadChartOptionBar2();
      });
    },

    reloadChartArea3() {
      FVisitorService.countVisitorByCity().then((response) => {
        this.categoriesBar3 = response.data
          .sort((a, b) => (a.totalVisitorCount > b.totalVisitorCount ? -1 : 1))
          .map((item) => item.city);
        this.seriesBar3 = [];
        let theData = [];
        this.categoriesBar3.forEach((x) => {
          const arr = response.data.filter((f) => f.city === x);
          if (arr.length > 0) theData.push(arr[0].totalVisitorCount);
          else theData.push(0);
        });
        this.seriesBar3.push({
          data: theData,
        });

        this.reloadChartOptionBar3();
      });
    },

    reloadChartPie1() {
      FVisitorService.countVisitorByCity().then((response) => {
        this.labelsPie1 = response.data
          .sort((a, b) => (a.totalVisitorCount > b.totalVisitorCount ? -1 : 1))
          .map((item) => item.city);
        this.seriesPie1 = [];
        this.labelsPie1.forEach((x) => {
          const arr = response.data.filter((f) => f.city === x);
          if (arr.length > 0) this.seriesPie1.push(arr[0].totalVisitorCount);
          else this.seriesPie1.push(0);
        });

        this.reloadChartOptionPie1();
      });
    },

    reloadChartOptionBar1() {
      this.chartOptionsBar1 = {
        chart: {
          height: 300,
          type: "area",
          background: "#f8f8f8", // Warna latar belakang grafik
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: this.categoriesBar1,
          title: {
            text: "Bulan",
          },
          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Jumlah Pengunjung",
          },
        },
        tooltip: {
          theme: "dark",
        },
      };
    },

    reloadChartOptionBar2() {
      this.chartOptionsBar2 = {
        chart: {
          height: 300,
          type: "area",
          background: "#f8f8f8", // Warna latar belakang grafik
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: this.categoriesBar2,
          title: {
            text: "Minggu ke-",
          },
          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Jumlah Pengunjung",
          },
        },
        tooltip: {
          theme: "dark",
        },
      };
    },

    reloadChartOptionBar3() {
      this.chartOptionsBar3 = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.categoriesBar3,
          title: {
            text: "Jumlah Pengunjung",
          },
        },
      };
    },

    reloadChartOptionPie1() {
      this.chartOptionsPie1 = {
        chart: {
          width: 500,
          type: "pie",
        },
        labels: this.labelsPie1,
        fill: {
          type: "gradient",
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500,
                type: "pie",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    getWeekNumber(date) {
      const d = new Date(date);
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 4 - (d.getDay() || 7));
      const yearStart = new Date(d.getFullYear(), 0, 1);
      const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return weekNumber;
    },
  },
  mounted() {
    this.reloadChartArea1();
    this.reloadChartArea2();
    this.reloadChartArea3();
    this.reloadChartPie1();
  },
};
</script>

<style scoped>
</style>